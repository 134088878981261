import React from 'react';
import './index.scss';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const Index = () => {
    const onChange = (key) => {
        //console.log(key);
    };
    return (
        <div className='Terms__Container'>
            <h1>Usage Policies</h1>
            <p>Usage Policies for AI Real Estate</p>
            <p>By accessing or using our services, you agree to these usage policies.</p>
            <Collapse onChange={onChange}>
                <Panel header="Usage Restrictions" key="1">
                    <p>You may use our AI search engine for real estate only in accordance with these usage policies. You may not use our service for any illegal or unauthorized purposes. You may not use our service to violate the rights of any third party, including intellectual property rights and privacy rights.</p>
                </Panel>
                <Panel header="Prohibited Activities" key="2">
                    <p dir="ltr"><span>You may not engage in any of the following activities when using our AI search engine for real estate:</span></p>
                    <p dir="ltr"><span><span>&nbsp;</span></span></p>
                    <ul>
                        <li dir="ltr">
                            <p dir="ltr"><span>Accessing or using our service in any way that violates these usage policies</span></p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr"><span>Attempting to gain unauthorized access to our service or our systems</span></p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr"><span>Introducing viruses, malware, or other harmful code to our service</span></p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr"><span>Collecting or harvesting any information from our service without our prior written consent</span></p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr"><span>Impersonating any person or entity or misrepresenting your affiliation with any person or entity</span></p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr"><span>Using our service to send spam or other unsolicited messages</span></p>
                        </li>
                        <li dir="ltr">
                            <p dir="ltr"><span>Compliance with Laws:</span></p>
                        </li>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>You are responsible for complying with all applicable laws and regulations when using our AI search engine for real estate. You may not use our service in violation of any laws or regulations.</span></p>
                            </li>
                        </ul>
                    </ul>
                </Panel>
                <Panel header="Monitoring and Enforcement" key="3">
                    <p dir="ltr">We reserve the right to monitor your use of our AI search engine for real estate to ensure compliance with these usage policies. We may take any appropriate action, including suspending or terminating your access to our service, if we determine that you have violated these usage policies.</p>
                </Panel>
                <Panel header="Disclaimers" key="4">
                    <p dir="ltr">We provide our AI search engine for real estate on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the availability, reliability, or accuracy of our service.</p>
                </Panel>
                <Panel header="Limitation of Liability" key="5">
                    <p dir="ltr">We are not liable for any damages, including but not limited to direct, indirect, incidental, special, or consequential damages, arising from your use of our AI search engine for real estate. You acknowledge that your use of our service is at your own risk.</p>
                </Panel>
                <Panel header="Modifications" key="6">
                    <p dir="ltr">We reserve the right to modify these usage policies at any time. Your continued use of our service after any modifications to these usage policies constitutes your acceptance of the modified policies.</p>
                </Panel>
                <Panel header="Governing Law" key="7">
                    <p dir="ltr">These usage policies shall be governed by and construed in accordance with the laws of the jurisdiction in which we operate. Any disputes arising from or related to these usage policies shall be resolved in the courts of that jurisdiction.</p>
                </Panel>
            </Collapse>
        </div>
    );
};

export default Index;
