import React, { useEffect, useState } from 'react';
import './style.scss';
// Import UI
import { Avatar, Spin } from 'antd';
import Select from 'react-select';
// Import Icons
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, notification } from 'antd';
import CreatableSelect from 'react-select/creatable';
// Import Services
import moment from 'moment'

// To Delete
import mockupData from './../../mockData.json';
const { Search } = Input;
const { TextArea } = Input;

const { Option } = Select;


const Index = () => {
    const [conversationsLog, setconversationsLog] = useState([]);
    const [conversationsLogAll, setconversationsLogAll] = useState([]);
    const [selectedChat, setselectedChat] = useState(undefined);
    const [searchInputText, setsearchInputText] = useState(undefined);
    const [loading, setloading] = useState(true);
    const [listOfTags, setlistOfTags] = useState([]);
    const [tagsFilter, settagsFilter] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        //console.log("🚀 ~ file: index.jsx:14 ~ mockupData:", mockupData);
        /* setloading(false);
        setconversationsLog(mockupData);
        setconversationsLogAll(mockupData);
        setselectedChat(mockupData[0]);

        let listOfTagsUsed = [];
        mockupData.forEach(eachChat => {
            if (!eachChat.tags) return;
            eachChat.tags.map(_eachTag => {
                if (!listOfTagsUsed.includes(_eachTag)) {
                    listOfTagsUsed.push(_eachTag);
                }
            });
        });
        setlistOfTags(listOfTagsUsed); */

        //return;
        //fetch('http://localhost:4000/save-conversations', {
        fetch('https://ai-server-a647c.web.app/save-conversations', {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then(async (aiResponse) => {
                let messagesList = aiResponse.result.filter(single => single.messages.length > 3).map(single => {
                    single.label = single.id;
                    single.value = single.id;
                    return single;
                });
                await setconversationsLog(messagesList);
                await setconversationsLogAll(messagesList);
                await setloading(false);
                setselectedChat(messagesList[0])

                let listOfTagsUsed = [];
                messagesList.forEach(eachChat => {
                    if (!eachChat.tags) return;
                    eachChat.tags.map(_eachTag => {
                        if (!listOfTagsUsed.includes(_eachTag)) {
                            listOfTagsUsed.push(_eachTag);
                        }
                    });
                });
                setlistOfTags(listOfTagsUsed);
            });
    }, []);

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Notification Title',
            description:
                'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        });
    };

    const updateChatById = () => {
        let updateObject = {
            tags: selectedChat.tags || [],
            comments: selectedChat.comments || '',
        };
        fetch(`https://ai-server-a647c.web.app/update-conversations/${selectedChat.id}`, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updateObject)
        })
            .then((aiResponse) => {
                let updateChatList = conversationsLogAll;
                updateChatList = updateChatList.map(e => {
                    if (e.id === selectedChat.id) {
                        e.comments = selectedChat.comments;
                        e.tags = selectedChat.tags;
                    }
                    return e;
                });
                setconversationsLogAll(updateChatList);
                openNotificationWithIcon('success');
            })
            .catch(err => {
                //console.log("🚀 ~ file: index.jsx:86 ~ updateChatById ~ err:", err);
                openNotificationWithIcon('error');

            });
    };

    const updateListOfTags = () => {
        let listOfTagsUsed = [];
        conversationsLogAll.forEach(eachChat => {
            if (!eachChat.tags) return;
            eachChat.tags.map(_eachTag => {
                if (!listOfTagsUsed.includes(_eachTag)) {
                    listOfTagsUsed.push(_eachTag);
                }
            });
        });
        setlistOfTags(listOfTagsUsed);
    };

    const applyFilters = ({ searchInputText, tagsFilter }) => {
        let filteredChats = conversationsLogAll;
        if (searchInputText) {
            filteredChats = filteredChats.filter(singleChat => {
                if (singleChat.messages.some(singleMessage => singleMessage.content.includes(searchInputText))) return true;
                if (singleChat.comments?.toLowerCase().includes(searchInputText.toLowerCase())) return true;
            });
        }
        if (tagsFilter.length) {
            let plainTagsArray = tagsFilter.map(e => e.value);
            filteredChats = filteredChats.filter(e => {
                return e.tags?.some(v => plainTagsArray.includes(v));
            });
        }
        setconversationsLog(filteredChats);
        if (!filteredChats.find(e => e.id === selectedChat.id)) {
            setselectedChat(undefined);
        }


    }

    if (loading) {
        return <div className="example">
            <Spin />
        </div>;
    }

    return (
        <div className='Conversations__Log'>
            {contextHolder}
            {/* <Select
                showSearch
                placeholder={'Select Conversation'}
                classNamePrefix="react-select"
                className="react-select"
                style={{
                    width: 120,
                }}
                value={selectedChat || undefined}
                onChange={e => {
                    setselectedChat(e);
                }}
                options={conversationsLog}
                formatOptionLabel={({ value, label, customAbbreviation }) => {
                    return (
                        <div style={{ display: "block" }}>
                            <div>{label}</div>
                            <div style={{ marginTop: "4px", color: "#ccc" }}>
                                ( {conversationsLog.find(e => e.id === label).messages.length} messages)
                            </div>
                        </div>
                    );
                }}
            /> */}
            <div className='Conversations__Log--Dashboard'>
                <div className='Left__Block'>
                    <div className='Search__Params'>
                        <Search
                            placeholder="Search Chat"
                            onChange={async (e) => {
                                let textFilter = e.target.value.trim();
                                await setsearchInputText(textFilter);
                                applyFilters({
                                    searchInputText: textFilter,
                                    tagsFilter: tagsFilter
                                });

                                return;
                                setconversationsLog(conversationsLogAll.filter(singleChat => singleChat.messages.some(singleMessage => singleMessage.content.includes(textFilter))));
                            }}
                            onSearch={(e) => {
                                console.log("🚀 ~ file: index.jsx:176 ~ Index ~ e:", e);

                            }}
                        />
                        <Select
                            defaultValue={[]}
                            value={tagsFilter}
                            onChange={async e => {
                                if (!e) settagsFilter([]);
                                await settagsFilter(e);
                                applyFilters({
                                    searchInputText: searchInputText,
                                    tagsFilter: e
                                });
                            }}
                            isMulti
                            name="colors"
                            options={listOfTags.map(e => {
                                return {
                                    value: e,
                                    label: e
                                };
                            })}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <span style={{ color: "#d3d3d3" }}>{conversationsLog.length} conversations available</span>
                    </div>
                    <div className='Available__Conversations'>
                        {!conversationsLog.length ?
                            <p>No conversations yet</p>
                            :
                            conversationsLog.map((e, index) => {
                                {/* let containsSearchText = false;
                                if (searchInputText && !e.messages.some(singleMessage => singleMessage.content.includes(searchInputText))) {
                                    return;

                                } */}

                                return <div
                                    style={{ backgroundColor: e.id === selectedChat?.id ? 'antiquewhite' : (index % 2 === 0 ? '#d3d3d3' : '') }}
                                    className='Single__Chat--Button'
                                    onClick={(singleChat) => {
                                        setselectedChat(conversationsLogAll.find(_single => _single.id === e.id));
                                    }}
                                >
                                    <p>{e.messages.length} messages</p>
                                    {e.comments && <p>"{e.comments}"</p>}
                                    <span>{e.tags?.map(single => <span style={{ backgroundColor: '#fffaab', marginRight: "4px", padding: '4px 8px', borderRadius: "8px" }}>#{single} </span>)}</span>


                                    <p>{e.messages[1]?.content}</p>
                                    <span>
                                        <p><b>Created at:</b> {e?.createdAt ? moment(e?.createdAt).format('DD-MM-YYYY') : ''}</p>
                                        <p><b>Updated at:</b> {e?.updatedAt ? moment(e?.updatedAt).format('DD-MM-YYYY') : ''}</p>
                                    </span>
                                </div>;
                            })}

                    </div>
                </div>
                <div className='Right__Block'>
                    {!selectedChat ?
                        <span>No conversation selected</span>
                        :
                        <>
                            <div className='Top__Block'>
                                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <Button
                                        type="primary"
                                        disabled={JSON.stringify(selectedChat) === JSON.stringify(conversationsLogAll.find(_each => _each.id === selectedChat.id))}
                                        onClick={() => {
                                            updateChatById();
                                            updateListOfTags();
                                        }}
                                    >Save</Button>
                                    <p style={{ color: "#d3d3d3" }}> {selectedChat?.id}</p></span>

                                <span>
                                    <label>Comments</label>
                                    <TextArea
                                        rows={4}
                                        onChange={event => setselectedChat({ ...selectedChat, comments: event.target.value })}
                                        value={selectedChat?.comments || ''}
                                    />
                                </span>
                                <span>
                                    <label>Tags</label>
                                    <CreatableSelect
                                        isClearable
                                        isMulti
                                        value={selectedChat.tags?.length ? selectedChat.tags.map(singleTag => ({ value: singleTag, label: singleTag })) : []}
                                        onChange={e => {
                                            let list = e.map(v => v.value);
                                            setselectedChat({ ...selectedChat, tags: list });
                                        }}
                                        onCreateOption={async (newOption) => {
                                            let arrayOfTags = selectedChat.tags?.length ? selectedChat.tags : [];
                                            arrayOfTags.push(newOption);
                                            await setselectedChat({ ...selectedChat, tags: arrayOfTags });
                                        }}
                                        options={listOfTags?.map(singleTag => ({ value: singleTag, label: singleTag })) || []}
                                        placeholder={'Select Tags for this chat...'}
                                        className="Creatable__Select"
                                        classNamePrefix="Creatable"

                                    />
                                </span>
                                <div>
                                    <div><b>Created at:</b> {selectedChat?.createdAt ? moment(selectedChat?.createdAt).format('DD-MM-YYYY') : ''}</div>
                                    <div><b>Updated at:</b> {selectedChat?.updatedAt ? moment(selectedChat?.updatedAt).format('DD-MM-YYYY') : ''}</div>
                                </div>
                            </div>
                            <div className='Container'>
                                {/* <h6>{selectedChat?.id}</h6> */}
                                <div className='Chat__Log'>
                                    {selectedChat?.messages.map(e => {
                                        if (e.role === 'system') {
                                            return <div className='Single__Text'>
                                                <Avatar style={{ backgroundColor: '#f9f9f9', color: 'black', display: 'block', minWidth: '32px' }} >Sy</Avatar>
                                                <div className='system'>{e.content}</div>
                                            </div>;
                                        }
                                        if (e.role === 'user') {
                                            return <div className='Single__Text' >
                                                <Avatar style={{ backgroundColor: 'orange', color: 'black', display: 'block', minWidth: '32px' }} >Us</Avatar>
                                                <div className='user'>{e.content}</div>
                                            </div>;
                                        }
                                        if (e.role === 'assistant') {
                                            return <div className='Single__Text' >
                                                <Avatar style={{ backgroundColor: '#F2C94C', color: 'black', display: 'block', minWidth: '32px' }} >AI</Avatar>
                                                <div className='ai'>{e.content}</div>
                                            </div>;
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </>}
                </div>
            </div>

        </div>
    );
};

export default Index;
