import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'US',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            US: {
                translation: {
                    title: {
                        firstBlock: 'Your',
                        yellowBlock: 'AI-powered',
                        lastBlock: 'Real Estate Assistant',
                    },
                    subtitle: 'Get answers to all of your real estate questions.',
                    shareButton: {
                        label: 'Share'
                    },
                    shareModal: {
                        title: 'Share this page',
                        shareMessageCheckboxLabel: 'Share with message',
                        shareImageCheckboxLabel: 'Download chat window'
                    },
                    searchInput: {
                        placeholder: 'Ask me anything about Real Estate -hit enter-',
                        safeMessageBellow: 'This product is in beta and its accuracy may be limited. AI Real Estate is not liable for content generated.'
                    },
                    examples: {
                        title: 'Examples',
                        example1: {
                            label: 'Apartment value in London?',
                            prompt: 'What’s the value of a flat in London?'
                        },
                        example2: {
                            label: 'I’m looking for a warehouse in San Francisco',
                            prompt: 'I’m looking for a warehouse in San Francisco'
                        },
                        example3: {
                            label: 'Tell me about the retail scene in Paris',
                            prompt: 'Tell me about the retail scene in Paris'
                        },
                        example4: {
                            label: 'What’s the fashion district in New York?',
                            prompt: 'What’s the fashion district in New York?'
                        },
                        example5: {
                            label: 'Tips for running a pop-up shop',
                            prompt: 'Tips for running a pop-up shop'
                        },
                        example6: {
                            label: 'How do I find a showroom for Fashion Week?',
                            prompt: 'How do I find a showroom for Fashion Week?'
                        },
                        example7: {
                            label: 'Best retail neighborhood in Miami?',
                            prompt: 'What’s the best retail neighborhood in Miami?'
                        },
                        example8: {
                            label: 'Renting a store in LA?',
                            prompt: 'What do I need to know when renting a store in LA?'
                        },
                    },
                    footer: {
                        brandMessage: 'Elevate your real estate game.',
                        terms: 'Terms',
                        privacy: 'Privacy',
                        usagePolicies: 'Usage Policies',
                        poweredBy: 'powered by'
                    }
                }
            },
            CN: {
                translation: {
                    title: {
                        firstBlock: '你的',
                        yellowBlock: 'AI驅動',
                        lastBlock: '房地產中介',
                    },
                    subtitle: '獲取所有房地產問題的答案。',
                    shareButton: {
                        label: '分享'
                    },
                    shareModal: {
                        title: '分享此頁面',
                        shareMessageCheckboxLabel: '與消息共享',
                        shareImageCheckboxLabel: '下載聊天窗口'
                    },
                    searchInput: {
                        placeholder: '在這裡輸入房地產問題',
                        safeMessageBellow: '該產品處於beta中，其準確性可能受到限制。AI房地產對生成的內容不承擔任何責任。'
                    },
                    examples: {
                        title: '例子',
                        example1: {
                            label: '倫敦的公寓價值？',
                            prompt: '倫敦一居室公寓的當前市場價值是多少？'
                        },
                        example2: {
                            label: '在舊金山來源？',
                            prompt: '如何在舊金山採購大倉庫？'
                        },
                        example3: {
                            label: '紐約市最高的欣賞？',
                            prompt: '哪些紐約市社區對財產價值的讚賞最高？'
                        },
                        example4: {
                            label: '房地產經紀人的角色是什麼？',
                            prompt: '房地產經紀人的角色是什麼？'
                        },
                        example5: {
                            label: '疑問購買物業',
                            prompt: '您應該在購買房地產之前問房地產經紀人？'
                        },
                        example6: {
                            label: '邁阿密最好的零售社區？',
                            prompt: '邁阿密最好的零售社區？'
                        },
                        example7: {
                            label: '洛杉磯很棒的辦公室？',
                            prompt: '如何在洛杉磯提供一個很棒的辦公空間？'
                        },
                        example8: {
                            label: '丹佛的頂級CRE經紀人？',
                            prompt: '丹佛的頂級CRE經紀人列表？'
                        },
                    },
                    footer: {
                        brandMessage: '提升您的房地產遊戲。',
                        terms: '術語',
                        privacy: '隱私',
                        usagePolicies: '使用政策',
                        poweredBy: '供電'
                    }
                }
            },
            KR: {
                translation: {
                    title: {
                        firstBlock: '당신의',
                        yellowBlock: 'AI 구동',
                        lastBlock: '부동산 중개인',
                    },
                    subtitle: '모든 부동산 질문에 대한 답변을 얻으십시오.',
                    shareButton: {
                        label: '공유하다'
                    },
                    shareModal: {
                        title: '이 페이지를 공유하다',
                        shareMessageCheckboxLabel: '메시지와 공유하십시오',
                        shareImageCheckboxLabel: '채팅 창을 다운로드하십시오'
                    },
                    searchInput: {
                        placeholder: '여기에서 부동산 질문을 입력하십시오',
                        safeMessageBellow: '이 제품은 베타에 있으며 정확도는 제한 될 수 있습니다.AI 부동산은 생성 된 콘텐츠에 대해 책임을지지 않습니다.'
                    },
                    examples: {
                        title: '예',
                        example1: {
                            label: '런던의 아파트 가치?',
                            prompt: '런던에있는 1 베드룸 아파트의 현재 시장 가치는 얼마입니까?'
                        },
                        example2: {
                            label: '샌프란시스코의 창고를 소스 하시겠습니까?',
                            prompt: '샌프란시스코에서 훌륭한 창고를 공급하는 방법은 무엇입니까?'
                        },
                        example3: {
                            label: 'NYC에서 가장 높은 감사?',
                            prompt: 'NYC 지역은 재산 가치에서 가장 높은 인식을 보았습니까?'
                        },
                        example4: {
                            label: '부동산 중개인의 역할은 무엇입니까?',
                            prompt: '부동산 중개인의 역할은 무엇입니까?'
                        },
                        example5: {
                            label: '부동산 구매 질문',
                            prompt: '부동산을 구매하기 전에 부동산 중개인에게 물어봐야합니까?'
                        },
                        example6: {
                            label: '마이애미 최고의 소매 동네?',
                            prompt: '마이애미 최고의 소매 동네?'
                        },
                        example7: {
                            label: '로스 앤젤레스에있는 훌륭한 사무실?',
                            prompt: '로스 앤젤레스에서 훌륭한 사무실 공간을 공급하는 방법?'
                        },
                        example8: {
                            label: '덴버의 최고 CRE 브로커?',
                            prompt: '덴버의 최고 CRE 브로커 목록?'
                        },
                    },
                    footer: {
                        brandMessage: '부동산 게임을 높이십시오.',
                        terms: '자귀',
                        privacy: '은둔',
                        usagePolicies: '사용 정책',
                        poweredBy: '구동'
                    }
                }
            },
            IT: {
                translation: {
                    title: {
                        firstBlock: 'Il tuo',
                        yellowBlock: 'agente immobiliare AI',
                        lastBlock: '',
                    },
                    subtitle: 'Ottieni risposte a tutte le tue domande immobiliari.',
                    shareButton: {
                        label: 'Condividere'
                    },
                    shareModal: {
                        title: 'Condividi questa pagina',
                        shareMessageCheckboxLabel: 'Condividi con il messaggio',
                        shareImageCheckboxLabel: 'Scarica la finestra di chat'
                    },
                    searchInput: {
                        placeholder: 'Digita una domanda immobiliare qui',
                        safeMessageBellow: 'Questo prodotto è in beta e la sua precisione può essere limitata.AI Real Estate non è responsabile per i contenuti generati.'
                    },
                    examples: {
                        title: 'Esempi',
                        example1: {
                            label: 'Valore di un appartamento a Londra?',
                            prompt: 'Qual è l`attuale valore di mercato di un appartamento con una camera da letto a Londra?'
                        },
                        example2: {
                            label: 'Cerchi un magazzino a San Francisco?',
                            prompt: 'Come procurarsi un grande magazzino a San Francisco?'
                        },
                        example3: {
                            label: 'La migliore rendita a New York?',
                            prompt: 'Quali quartieri di New York hanno visto il massimo apprezzamento del valore della proprietà?'
                        },
                        example4: {
                            label: 'Qual è il ruolo di un agente immobiliare?',
                            prompt: 'Qual è il ruolo di un agente immobiliare?'
                        },
                        example5: {
                            label: 'Domande riguardo all`acquisto di proprietà',
                            prompt: 'Domande che dovresti porre a un agente immobiliare prima di acquistare una proprietà?'
                        },
                        example6: {
                            label: 'Miglior quartiere per pop-up shop a Miami?',
                            prompt: 'Miglior quartiere per pop-up shop a Miami?'
                        },
                        example7: {
                            label: 'Grande ufficio a Los Angeles?',
                            prompt: 'Come procurarsi un grande spazio per uffici a Los Angeles?'
                        },
                        example8: {
                            label: 'I migliori agenti immobiliari a Denver?',
                            prompt: 'Elenco dei migliori broker Cre a Denver?'
                        },
                    },
                    footer: {
                        brandMessage: 'Eleva il tuo gioco immobiliare.',
                        terms: 'Termini',
                        privacy: 'privacy',
                        usagePolicies: 'Politiche di utilizzo',
                        poweredBy: 'offerto da'
                    }
                }
            },
            NL: {
                translation: {
                    title: {
                        firstBlock: 'Jouw',
                        yellowBlock: 'AI-aangedreven',
                        lastBlock: 'Makelaar',
                    },
                    subtitle: 'Krijg antwoorden op al uw vragen over onroerend goed.',
                    shareButton: {
                        label: 'Deel'
                    },
                    shareModal: {
                        title: 'Deel deze pagina',
                        shareMessageCheckboxLabel: 'Deel met bericht',
                        shareImageCheckboxLabel: 'Download chatvenster'
                    },
                    searchInput: {
                        placeholder: 'Typ hier een onroerendgoedvraag',
                        safeMessageBellow: 'Dit product is in bèta en de nauwkeurigheid ervan kan beperkt zijn.AI onroerend goed is niet aansprakelijk voor de gegenereerde inhoud.'
                    },
                    examples: {
                        title: 'Voorbeelden',
                        example1: {
                            label: 'Appartementwaarde in Londen?',
                            prompt: 'Wat is de huidige marktwaarde van een appartement met één slaapkamer in Londen?'
                        },
                        example2: {
                            label: 'Bron een magazijn in San Francisco?',
                            prompt: 'Hoe een geweldige magazijn te vinden in San Francisco?'
                        },
                        example3: {
                            label: 'Hoogste waardering in NYC?',
                            prompt: 'Welke NYC -buurten hebben de hoogste waardering in de waarde van onroerend goed gezien?'
                        },
                        example4: {
                            label: 'Wat is de rol van een makelaar?',
                            prompt: 'Wat is de rol van een makelaar?'
                        },
                        example5: {
                            label: 'Vragen die een onroerend goed kopen',
                            prompt: 'Vragen die u een makelaar moet stellen voordat u een onroerend goed koopt?'
                        },
                        example6: {
                            label: 'Beste winkel in Miami?',
                            prompt: 'Beste winkel in Miami?'
                        },
                        example7: {
                            label: 'Geweldig kantoor in Los Angeles?',
                            prompt: 'Hoe een geweldige kantoorruimte te vinden in Los Angeles?'
                        },
                        example8: {
                            label: 'Top CRE -makelaars in Denver?',
                            prompt: 'Lijst met top CRE -makelaars in Denver?'
                        },
                    },
                    footer: {
                        brandMessage: 'Verhoog uw onroerendgoedspel.',
                        terms: 'Voorwaarden',
                        privacy: 'privacy',
                        usagePolicies: 'Gebruiksbeleid',
                        poweredBy: 'aangedreven door'
                    }
                }
            },
            FR: {
                translation: {
                    title: {
                        firstBlock: 'Votre',
                        yellowBlock: `assistant immobilier propulsé par l'IA`,
                        lastBlock: '',
                    },
                    subtitle: 'Obtenez des réponses à toutes vos questions immobilières.',
                    shareButton: {
                        label: 'Partager'
                    },
                    shareModal: {
                        title: 'Partager cette page',
                        shareMessageCheckboxLabel: 'Partager avec un message',
                        shareImageCheckboxLabel: 'Télécharger la fenêtre de chat'
                    },
                    searchInput: {
                        placeholder: 'Tapez une question immobilière ici',
                        safeMessageBellow: 'Ce produit est en version bêta et sa précision peut être limitée. AI REAL ESTATE n`est pas responsable du contenu généré.'
                    },
                    examples: {
                        title: 'Exemples',
                        example1: {
                            label: 'Valeur d`un logement à Londres?',
                            prompt: 'Quelle est la valeur marchande actuelle d`un appartement d`une chambre à Londres?'
                        },
                        example2: {
                            label: 'Trouver un entrepôt à San Francisco?',
                            prompt: 'Comment trouver un grand entrepôt à San Francisco?'
                        },
                        example3: {
                            label: 'Quartiers côtés à NYC?',
                            prompt: 'Quels quartiers de New York ont connu la plus grande appréciation de la valeur de la propriété?'
                        },
                        example4: {
                            label: 'Quel est le rôle d`un agent immobilier?',
                            prompt: 'Quel est le rôle d`un agent immobilier?'
                        },
                        example5: {
                            label: 'Questions à poser avant d`acheter',
                            prompt: 'Des questions que vous devriez poser un agent immobilier avant d`acheter une propriété?'
                        },
                        example6: {
                            label: 'Meilleur quartier marchand à Miami?',
                            prompt: 'Meilleur quartier marchand à Miami?'
                        },
                        example7: {
                            label: 'Trouver un grand bureau à Los Angeles?',
                            prompt: 'Comment trouver un grand espace de bureau à Los Angeles?'
                        },
                        example8: {
                            label: 'Meilleurs agents immobiliers de bureaux?',
                            prompt: 'Liste des Meilleurs agents immobiliers de bureaux?'
                        },
                    },
                    footer: {
                        brandMessage: 'Élevez votre jeu immobilier.',
                        terms: 'Conditions',
                        privacy: 'Confidentialité',
                        usagePolicies: 'Politiques d`utilisation',
                        poweredBy: 'alimenté par'
                    }
                }
            },
        }
    });


export default i18n;