import React from 'react';
import './style.scss';
import { BubbleGroup } from 'react-chat-ui';

const Index = (props) => {
    //console.log("🚀 ~ file: index.jsx:6 ~ Index ~ props:", props.message);
    let isPerson = !!props.message.id;
    if (props.message.message === '...') {
        isPerson = !isPerson;
    }
    return (
        <div key={props.message.id} className={(!isPerson ? 'Ai' : 'Person') + ' Message'}>
            <span>{props.message?.senderName}</span>
            <span className='Bubble'>{props.message.message}</span>
        </div>
    );
};

export default Index;
