import React, { useEffect, useState, createRef, useRef } from 'react';
//import { test } from './openAi';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
// Import UI
import { ChatFeed, Message } from 'react-chat-ui';
import Icon, { MailOutlined, SearchOutlined, SendOutlined, ShareAltOutlined, WechatOutlined, CameraFilled } from '@ant-design/icons';
import { Input, Button } from 'antd';
import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Modal, Popover, Checkbox } from 'antd';
import MyChatBubble from './Components/BubbleMessage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactFlagsSelect from "react-flags-select";
// Import Images
import TextLogo from './Assets/textLogo.png';
import SharableImage from './Assets/SharableImage.png';
import CircuitBoard from './Assets/circuitBoard.png';
import FacebookIconPng from './Assets/FacebookIcon.png';
import LinkedinIconPng from './Assets/LinkedinIcon.png';
import Lightbulb from './Assets/Lightbulb.png';
import { SocialIcon } from 'react-social-icons';
// import Services
import { useScreenshot, createFileName } from "use-react-screenshot";
import { US } from 'country-flag-icons/react/3x2';
import i18next from './i18n';
import { useTranslation, withTranslation } from 'react-i18next';
import { Route, Link, Routes, useSearchParams, Navigate, useParams } from "react-router-dom";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { useNavigate, createSearchParams } from 'react-router-dom'
// import SVG from "react-inlinesvg";
// Import Views
import Terms from './Views/Terms';
import Privacy from './Views/Privacy';
import Usage from './Views/Usage';
import ConversationsLog from './Views/ConversationsLog';

//const { TextArea } = Input;

const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

function App(props) {
  const [newMessage, setnewMessage] = useState(''); //Hello. What can you do?
  const [fullConversation, setfullConversation] = useState('');
  const [isTyping, setisTyping] = useState(false);
  const [emailGathered, setemailGathered] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [takingScreenShot, settakingScreenShot] = useState(false);
  const [email, setemail] = useState('');
  const [messages, setmessages] = useState([]);
  const [metaDataArray, setmetaDataArray] = useState([
    { "role": "system", "content": "You are a helpful assistant. The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly. Never mention you are a AI. Your name is 'Ai Real Estate Assistant'. Avoid apologies, try to always find an answer. If questions aren't related with real estate answer `I'm only trained on Real Estate data. Example: Residential, Commercial Real Estate, etc.` If question about where to find/search commercial spaces to rent, recommend Storefront website. Website is `www.thestorefront.com`. Don't curse. When providing numbers or other exact data you need to warn that it is accordingly to last available data." },
    //{ "role": "user", "content": "Hello. What can you do?" },
  ]);
  const [userId, setuserId] = useState(undefined);
  const [isSocialShareModalOpen, setisSocialShareModalOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [translationCode, settranslationCode] = useState(localStorage.getItem('i18nextLng') || 'US');
  const [shareWithChatId, setshareWithChatId] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const ref = createRef(null);
  const refIntoView = useRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });
  const [aiPromptsSuggestions, setaiPromptsSuggestions] = useState([]);

  const navigate = useNavigate();
  const { lang } = useParams()

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  useEffect(() => {
    //askOpenAi();
    //localStorage.removeItem('emailGathered');
    //const lng = navigator.language;


    //i18n.changeLanguage("NL")

    if (localStorage.getItem('emailGathered')) {
      setemailGathered(true);
    };

    // If will make sure first parameter is for language
    if (!['US', 'KO', 'IT', 'NL', 'CN', 'FR'].includes(window.location.pathname.split('/')[1])) {
      navigate({
        pathname: '',
      });

    }

    searchParams.get('chat') && getSavedChat()

    setuserId(uuidv4());

  }, []);

  useEffect(() => {
    /* if (!searchParams.get('lng')) {
      var search = window.location.search.substring(1);
      let params = search.length ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {};


      navigate({
        pathname: window.location.pathname,
        search: createSearchParams({
          ...params,
          lng: i18n.language || "US"
        }).toString()
      });
    } */
  });

  useEffect(() => {
    //console.log('Check metaDataArray: ', metaDataArray);
    //console.log('Check messages: ', messages);
    if (isTyping) {
      askOpenAi();
    }
    //console.log("This is id: ", userId);
    if (messages.length > 1) {
      fetch('https://ai-server-a647c.web.app/save-conversations', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "id": userId,
          "messages": metaDataArray,
        })
      });
    }
  }, [messages]);

  const getImage = () => takeScreenShot(ref.current).then((image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  });

  const getSavedChat = async () => {

    await fetch(`https://ai-server-a647c.web.app/save-conversations/${searchParams.get('chat')}`)
      .then((response) => response.json())
      .then(async (serverResponse) => {
        //console.log("🚀 ~ file: App.jsx:127 ~ .then ~ serverResponse:", serverResponse);
        if (serverResponse.messages) {
          await setmetaDataArray(serverResponse.messages);
          let messagesArray = [];
          serverResponse.messages.forEach(e => {
            if (e.role === 'system') return;
            if (e.role === 'user') {
              messagesArray.push(new Message({ id: 1, message: e.content, senderName: 'Me' }));
            } else {
              messagesArray.push(new Message({ id: 0, message: e.content, senderName: 'Your Real Estate Assistant' }));
            }
          });
          //console.log("🚀 ~ file: App.jsx:143 ~ .then ~ messagesArray:", messagesArray);
          await setmessages(messagesArray);

        }

      });
    setloading(true);

  }

  const saveConversation = () => {
    fetch('https://ai-server-a647c.web.app/save-conversations', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "messages": metaDataArray,
      })
    });
  };

  const saveEmailOnTable = async (emailData) => {
    await fetch('https://api.baserow.io/api/database/rows/table/151935/?user_field_names=true', {
      method: 'post',
      headers: new Headers({
        'Authorization': `Token ${'kBgpDNdSdDw96dF84VkoLqkey7Emr2zS'}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        "Email": emailData,
        "Notes": "",
        //"Active": true
      })
    })
      .then((response) => response.json())
      .then(async (baserow) => {
        return;
      })
      .catch(error => console.log(error));
    return;
  };

  const askOpenAi = async () => {
    //console.log('Calling?');
    //await fetch('http://localhost:4000/ai-realestate', {
    await fetch('https://ai-server-a647c.web.app/ai-realestate', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        "messages": metaDataArray
      })
    }).then((response) => response.json())
      .then(async (aiResponse) => {
        //console.log("🚀 ~ file: App.js:94 ~ .then ~ baserow:", aiResponse);

        await setmetaDataArray([...metaDataArray, { "role": "assistant", "content": aiResponse.response.message.content }]);


        getFollowUpPrompts(aiResponse.response.message.content, 3)

        await setmessages([...messages, new Message({ id: 0, message: aiResponse.response.message.content, senderName: 'Your Real Estate Assistant' })]);

        setisTyping(false);

      })
      .catch(error => {
        console.log(error);
        setisTyping(false);
      });
    refIntoView.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  /**
   * Get the follow up prompts for dynamic chat
   * @param {String} last message received by Open AI.
   * @param {Number} number of prompts to be generated by Open AI.
   * @returns {Null} Doesn't return but updates state with new prompts.
   */
  const getFollowUpPrompts = async (lastMessage, numberOfPrompts) => {

    try {
      //await fetch(`https://ai-server-a647c.web.app/ai-realestate/followup/${numberOfPrompts}`, {
      await fetch(`http://localhost:4000/ai-realestate/followup/${numberOfPrompts}`, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          "lastMessage": lastMessage || undefined
        })
      }).then((response) => response.json())
        .then(async (aiResponse) => {
          //console.log("🚀 ~ file: App.js:94 ~ .then ~ baserow:", aiResponse);
          //console.log("🚀 ~ file: App.js:94 ~ .then ~ baserow:", JSON.parse(aiResponse.response.text));
          let nextAiPromptsSuggestions = aiResponse.response.text ? JSON.parse(aiResponse.response.text) : [];
          setaiPromptsSuggestions(nextAiPromptsSuggestions);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const MainPage = () => {
    return <>
      <Modal
        //title="Leave your email to see AI answers"
        open={isModalOpen}
        footer={null}
      //onOk={handleOk} 
      //onCancel={handleCancel}
      >
        <form onSubmit={async (e) => {
          //console.log('Here???');
          e.preventDefault();
          // Send to airtable
          await saveEmailOnTable(email);
          localStorage.setItem('emailGathered', true);
          setemailGathered(true);
          askOpenAi();
          setisModalOpen(false);

        }}
        >
          <h4>Leave your email to see AI answers</h4>
          <Input
            prefix={<MailOutlined />}
            value={email}
            placeholder='Email...'
            required
            type='email'
            onChange={e => {
              //console.log("🚀 ~ file: App.js:175 ~ App ~ e:", e);
              setemail(e.target.value);
            }}
          />
          <button type='submit'>Proceed</button>
        </form>
      </Modal>
      <Modal
        title={t('shareModal.title')}
        open={isSocialShareModalOpen}
        onOk={() => setisSocialShareModalOpen(false)}
        onCancel={() => setisSocialShareModalOpen(false)}
        footer={[
          <button
            key="ok"
            onClick={() => setisSocialShareModalOpen(false)}>
            Ok
          </button>,

        ]}
      >

        <EmailShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          className="Demo__some-network__share-button m-2">
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <FacebookShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          hashtag={'#AIRealEstate '}
          className="Demo__some-network__share-button m-2">
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          className="Demo__some-network__share-button m-2">
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <PinterestShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          className="Demo__some-network__share-button m-2">
          <PinterestIcon size={32} round={true} />
        </PinterestShareButton>
        <RedditShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          className="Demo__some-network__share-button m-2">
          <RedditIcon size={32} round={true} />
        </RedditShareButton>
        <TelegramShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          className="Demo__some-network__share-button m-2">
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <TwitterShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          hashtags={['#AIRealEstate']}
          className="Demo__some-network__share-button m-2">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={`https://airealestate.io/${shareWithChatId ? '?chat=' + userId : ''}`}
          title={"Ai Real Estate"}
          media={"https://airealestate.io/static/media/SharableImage.37c1f75d2820edbd66b0.png"}
          className="Demo__some-network__share-button m-2">
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <div style={{ display: 'block' }}>
          <div>
            <Checkbox
              //size="small"  
              value={shareWithChatId}
              onChange={() => setshareWithChatId(!shareWithChatId)}
            />
            <span>{t('shareModal.shareMessageCheckboxLabel')}</span> <WechatOutlined style={{ fontSize: '18px' }} />
          </div>
          {/* <div>
                    <Checkbox
                      //size="small"
                      value={shareWithChatId}
                      onChange={() => setshareWithChatId(!shareWithChatId)}
                    />
                    <span>{t('shareModal.shareImageCheckboxLabel')}</span> <CameraFilled style={{ fontSize: '18px' }} />
                  </div> */}
          {/* {image && <img width={300} src={image} alt={"ScreenShot"} />} */}
          <button style={{ marginBottom: "10px" }} onClick={getImage}>
            {t('shareModal.shareImageCheckboxLabel')}
          </button>

        </div>
      </Modal>
      <span style={{ minHeight: '70vh', display: 'block' }}>
        <div
          className='Title'
          style={{ background: `url(${CircuitBoard})` }}
        >
          <h1>{t('title.firstBlock')} <span style={{ color: "#F2C94C" }}>{t('title.yellowBlock')}</span> {t('title.lastBlock')}</h1>
          {!!!messages.length && <>{/* <h6>Ask questions about real estate.</h6> */}<h6>{t('subtitle')}</h6></>}
        </div>

        {!!messages.length && <div className='Conversation__Box' id='container' ref={ref} style={{ maxHeight: isSocialShareModalOpen ? "100%" : '' }}>
          <div id="gradient" />
          <ChatFeed
            chatBubble={MyChatBubble} //Custom Bubble
            messages={messages} // Array: list of message objects
            isTyping={isTyping} // Boolean: is the recipient typing
            hasInputField={false} // Boolean: use our input, or use your own
            showSenderName={false} // show the name of the user who sent the message
            bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
            // JSON: Custom bubble styles
            bubbleStyles={
              {
                text: {
                  fontSize: 14
                },
                chatbubble: {
                  backgroundColor: 'rgba(0, 166, 126, 1)',
                  fontWeigth: 'bold',
                  borderRadius: 8,
                  padding: 16,
                  margin: 8
                }
              }
            }
          />

        </div>}
        <div
          className='Search__Input'
          style={{ marginBottom: '0 !important' }}
        >
          <button
            style={{
              color: '#727171',
              fontWeight: 'bold'
            }}
            onClick={(e) => {
              e.preventDefault();
              setisSocialShareModalOpen(true);
              //getImage();
            }}
          >
            <ShareAltOutlined />
            {t('shareButton.label')}
          </button>
        </div>
        {(!isTyping && aiPromptsSuggestions.length) ? <div className='Promps__Suggestions'>
          <Row className='First__Row'>
            {aiPromptsSuggestions.map((e, index) => {
              return <Col xs={12} sm={4} md={3} lg={3} key={index}>
                <Popover content={<p>{e}</p>} >
                  <button
                    className='First__Prompt'
                    onClick={() => {
                      let newMessage = e;
                      setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                      let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                      setmessages([...messages, newMessageToInsert]);
                      setisTyping(true);
                      document.getElementById('Chat__Input').focus();

                    }}
                  ><p>{e}</p></button>
                </Popover>
              </Col>;
            })}
          </Row>
        </div> : null}
        <form
          className='Search__Input'
          onSubmit={async e => {
            e.preventDefault();
            if (!newMessage.length) return;
            if (false && !emailGathered) { // This "false" is preventing from gathering email
              setisModalOpen(true);
              return;
            }

            //console.log('Debug: ', messages);
            setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
            let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
            setmessages([...messages, newMessageToInsert]);

            setnewMessage('');
            setisTyping(true);
            document.getElementById('Chat__Input').focus();

            //askOpenAi(); //testing behaviour locally first, then uncomment

          }}
        >



          <Input
            autoFocus
            suffix={<button
              onClick={() => {
                if (!newMessage.length) return;
                if (false && !emailGathered) { // This "false" is preventing from gathering email
                  setisModalOpen(true);
                  return;
                }

                //console.log('Debug: ', messages);
                setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                setmessages([...messages, newMessageToInsert]);

                setnewMessage('');
                setisTyping(true);
                document.getElementById('Chat__Input').focus();
              }}
            >
              <SearchOutlined />
            </button>}
            id={'Chat__Input'}
            placeholder={t('searchInput.placeholder')}
            value={newMessage}
            onChange={e => setnewMessage(e.target.value)}
          />
          {false && !!newMessage.length && <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={async () => {
              setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
              let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
              //console.log("🚀 ~ file: App.js:183 ~ App ~ newMessageToInsert:", newMessageToInsert);
              setmessages([...messages, newMessageToInsert]);

              setnewMessage('');
              setisTyping(true);
              document.getElementById('Chat__Input').focus();
            }} />}

          <p id="Legal__Message" ref={refIntoView}>{t('searchInput.safeMessageBellow')}</p>
        </form>
        {!!!messages.length && <div className='Promps__Suggestions'>
          <h5 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><img src={Lightbulb} alt="Lightbulb" style={{ padding: "2px", marginRight: '4px' }} /> {t('examples.title')}</h5>
          <Row className='First__Row'>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example1.prompt')}</p>} >
                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example1.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                ><p>{t('examples.example1.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example2.prompt')}</p>} >
                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example2.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                ><p>{t('examples.example2.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example3.prompt')}</p>} >

                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example3.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                ><p>{t('examples.example3.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example4.prompt')}</p>} >

                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example4.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                > <p>{t('examples.example4.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example5.prompt')}</p>} >

                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example5.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                ><p>{t('examples.example5.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example6.prompt')}</p>} >
                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example6.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                ><p>{t('examples.example6.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example7.prompt')}</p>} >

                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example7.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                ><p>{t('examples.example7.label')}</p></button>
              </Popover>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <Popover content={<p>{t('examples.example8.prompt')}</p>} >

                <button
                  className='First__Prompt'
                  onClick={() => {
                    let newMessage = t('examples.example8.prompt');
                    setmetaDataArray([...metaDataArray, { "role": "user", "content": newMessage }]);
                    let newMessageToInsert = new Message({ id: 1, message: newMessage, senderName: 'Me' });
                    setmessages([...messages, newMessageToInsert]);
                    setisTyping(true);
                    document.getElementById('Chat__Input').focus();

                  }}
                >{t('examples.example8.label')}</button>
              </Popover>
            </Col>
          </Row>
        </div>}
      </span>
    </>;
  };

  return (
    <div
      className="App"
      style={{ filter: isModalOpen ? 'blur(4px)' : '' }}
    >
      {/* <Navbar bg="white" variant="light" expand="md">
          <Container>
            <Navbar.Brand ><Link to='/'><img src={TextLogo} alt='logo' /></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <p className='Language' style={{ margin: 'auto', display: 'flex' }}><US title="United States" className="..." />
                  EN</p>
                <Nav.Link href="#">Home</Nav.Link>
                <Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}
      <header>
        <Link to='/'><img className='Main__Logo' src={TextLogo} alt="logo" /></Link>
        <span
          style={{
            position: "absolute",
            right: "5px"
          }}
        >
          <ReactFlagsSelect
            countries={["US", "FR", "NL", "IT", "KR", "CN"]}
            customLabels={{ US: "EN", FR: "FR", NL: "NL", IT: "IT", KR: "KO", CN: "CN" }}
            placeholder="Select Language"
            selected={translationCode}
            onSelect={(code) => {
              var search = window.location.search.substring(1);
              let params = search.length ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {};

              let pathname = window.location.pathname;
              let newPath = `/${code}/${pathname.substring(4)}`;

              navigate({
                pathname: newPath,
              });

              i18n.changeLanguage(code);
              settranslationCode(code);
              return;
            }}
          />
        </span>
      </header>
      <Routes>

        <Route path="/:lang/" >

          <Route exact path="terms" element={<Terms />} />
          <Route exact path="privacy" element={<Privacy />} />
          <Route exact path="usage-policies" element={<Usage />} />
          <Route exact path="conversations-log" element={<ConversationsLog />} />
          <Route exact path="" element={<MainPage />} />
          <Route exact path="*" element={<MainPage />} />

        </Route>
        <Route path="*" element={<Navigate to={`/${i18n.language}/`} replace />} />

        {/* <Route exact path="*" element={<Redirect />} /> */}

      </Routes>
      <footer>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to='/' style={{ width: 'fit-content' }}>
            <img className='Main__Logo' src={TextLogo} alt="logo" />
          </Link>
        </Row>
        <Row>
          <h6 style={{ fontSize: '28px', fontFamily: "'Gruppo', cursive", textAlign: "center" }}>{t('footer.brandMessage')}</h6>
        </Row>
        <Row className='Footer__Content'>
          {/* <Col xs={12} sm={12} md={12} lg={2}>
                <Link to='/'>
                  <img className='Main__Logo' src={TextLogo} alt="logo" />
                </Link>
              </Col> */}
          <Col xs={6} lg={1}>
            <a href='https://www.facebook.com/AI-Real-Estate-106700392382980' target='_blank'>
              {/* <img src={FacebookIconPng} alt='Facebook Logo' /> */}
              <FacebookIcon size={32} round={true} />
            </a>
          </Col>
          <Col xs={6} lg={1}>
            <a href='https://www.linkedin.com/company/ai-realestate/' target='_blank'>
              <LinkedinIcon size={32} round={true} />{/* <img src={LinkedinIcon} alt='Linkedin Logo' /> */}
            </a>
              </Col>
          <Col xs={4} sm={4} md={4} lg={2}>
            <Link to={`/${i18n.language}/terms`}>{t('footer.terms')}</Link>
          </Col>
          <Col xs={4} sm={4} md={4} lg={2}>
            <Link to={`/${i18n.language}/privacy`}>{t('footer.privacy')}</Link>
          </Col>
          <Col xs={4} sm={4} md={4} lg={3}>
            <Link to={`/${i18n.language}/usage-policies`}>{t('footer.usagePolicies')}</Link>
          </Col>

        </Row>


        <Row className='Powered__By'>
          <p>© {t('footer.poweredBy')} <a
            target="_blank"
            rel="noreferrer"
            href="https://www.thestorefront.com"
            style={{ fontWeight: 'bold' }}
          >Storefront</a> </p>
        </Row>
        <img src={SharableImage} alt="Sharable Image" style={{ display: 'none' }} />
      </footer>
    </div>
  );
}

export default withTranslation()(App);
